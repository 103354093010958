import { Box, Button, MenuItem, Modal, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './NewReceiptModal.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { receiptsSelector } from '../../services/selector';
import { Spinner } from '../../../../common/Spinner/Spinner';
import { Formik } from 'formik';
import SelectField from '../../../../common/CountrySelectField';
import InputField from '../../../../common/InputField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Moment } from 'moment/moment';
import { postCreateReceiptRequest } from '../../services/actions';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const calendarStyle = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
  '& .Mui-disabled': {
    backgroundColor: '#ECEFF1',
  },
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#ECEFF1',
  boxShadow: 24,
  p: 4,
  fontSize: '13px !important',
};

const inputStyle = {
  minWidth: 200,
  height: 30,
  fontSize: '13px !important',
  padding: 0,

  bgcolor: '#ffffff',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& .MuiAutocomplete-input': {
    padding: 0,
    paddingLeft: '10px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5',
  },
  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
    display: 'none',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

interface Props {
  isShow: boolean;
  closeModal: () => void;
  updateTable: () => void;
  countries: any;
}

export const NewReceiptModal: React.FC<Props> = ({ isShow, updateTable, closeModal, countries }) => {
  const dispatch = useDispatch();
  const { productListResponse, createLoading, createReceiptResponse } = useSelector(receiptsSelector);

  const [expirationDate, setExpDate] = useState<any>(new Date());
  const [aI17, setAI17] = useState<any>(new Date());
  const [mfDate, setMfDate] = useState<any>(new Date());
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(createReceiptResponse).length > 0 && createReceiptResponse?.Result === 1) {
      setHasError(false);

      setAI17(new Date());
      setExpDate(new Date());
      setMfDate(new Date());

      closeModal();

      updateTable();
    }

    if (createReceiptResponse?.Result === 0 && createReceiptResponse?.UserText) {
      setHasError(true);

      const timer = setTimeout(() => {
        setHasError(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [createReceiptResponse]);

  const options = productListResponse.filter((product: any) => product.label).filter(Boolean);

  const initialValue = {
    countryId: null,
    series: null,
    mrp: null,
    quantityToPrint: null,
    productId: null,
    productionLineId: null,
  };

  const handleChangeExpDate = (newDate: Moment | null) => {
    setHasError(false);

    setExpDate(newDate);
  };

  const handleChangeAI17Date = (newDate: Moment | null) => {
    setHasError(false);

    setAI17(newDate);
  };

  const handleChangeMfDate = (newDate: Moment | null) => {
    setHasError(false);

    setMfDate(newDate);
  };

  const getGTIN = (id: any) => {
    const selectedOption = options.find((option: any) => option.id === id);

    return selectedOption.gtin;
  };

  const getIsMRPBlocked = (id: any) => {
    if (!id) {
      return;
    }
    const countryList = countries.find((country: any) => country.id === id);
    return ['UZ', 'KZ', 'UA', 'CA', 'AZ', 'KG'].includes(countryList.code);
  };

  const getIsAI17Blocked = (id: any) => {
    if (!id) {
      return;
    }
    const countryList = countries.find((country: any) => country.id === id);
    return ['UZ', 'KZ'].includes(countryList.code);
  };

  const onSubmit = (values: any) => {
    dispatch(
      postCreateReceiptRequest({
        countryId: values.countryId,
        productId: values.productId,
        series: values.series,
        mrp: getIsMRPBlocked(values.countryId) ? null : values.mrp,
        aI17: getIsAI17Blocked(values.countryId) || !values.countryId ? null : aI17,
        expirationDate,
        mfDate,
        quantityToPrint: values.quantityToPrint,
        productionLineId: values.productionLineId,
      }),
    );
  };

  const getInternalName = (id: number) => {
    const selectedListItem = productListResponse.find((product: any) => product.id === id);
    return selectedListItem?.label || null;
  };

  return (
    <Modal
      open={isShow}
      onClose={() => {
        setAI17(new Date());
        setExpDate(new Date());
        setMfDate(new Date());

        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className={styles.spinnerWrapper}>{createLoading && <Spinner />}</div>

        <div className={styles.headerButtonsWrapper}>
          <CloseIcon
            onClick={() => {
              setAI17(new Date());
              setExpDate(new Date());
              setMfDate(new Date());

              closeModal();
            }}
          />
        </div>

        <span className={styles.header}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Меню створення нового рецепту
          </Typography>
        </span>

        <div className={styles.formWrapper}>
          <Formik initialValues={initialValue} onSubmit={() => undefined} enableReinitialize>
            {({ handleSubmit, setFieldValue, values }) => (
              <div>
                <form className={styles.form} onSubmit={handleSubmit}>
                  <span>Країна призначення:</span>
                  <SelectField
                    title=""
                    variant="noLabel"
                    name="countryCode"
                    value={values.countryId ?? ''}
                    dropdownValues={countries}
                    error={values.countryId === ''}
                    handleChangeSelect={(e: SelectChangeEvent<any>) => {
                      setFieldValue('countryId', e.target.value);
                    }}
                  >
                    {countries.map((country: any) => (
                      <MenuItem value={country.id} sx={{ fontSize: '13px' }}>
                        {country.code}, {country.countryName}
                      </MenuItem>
                    ))}
                  </SelectField>
                  <span>Продукт:</span>
                  <Autocomplete
                    disablePortal
                    id="productId"
                    options={options} // @ts-expect-error
                    value={getInternalName(values.productId)}
                    onChange={(e, value: any) =>
                      value ? setFieldValue('productId', value.id) : setFieldValue('productId', null)
                    }
                    sx={{
                      '& .MuiInputBase-root': inputStyle,
                      width: 300,
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <span>GTIN:</span>
                  <span>{values.productId ? getGTIN(values.productId) : ''}</span>

                  <span>Серія:</span>
                  <div className={styles.tooltipWrapper}>
                    <InputField
                      name="series"
                      maxLength={20}
                      value={values.series}
                      extendedOnChange={(event) => {
                        const inputValue = event.target.value;

                        if (inputValue.startsWith(' ')) {
                          event.target.value = inputValue.trim();
                          return;
                        }

                        event.target.value = inputValue.replace(/\s{2,}/g, ' ');

                        setFieldValue('series', event.target.value);
                      }}
                    />
                    <Tooltip title="Значення серії, допустмі символи 0-9, A-z" placement="right">
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </div>
                  <span>Номер виробничої лінії:</span>
                  <div className={styles.tooltipWrapper}>
                    <InputField name="productionLineId" type="number" maxLength={6} value={values.productionLineId} />
                    <Tooltip title="Допустмі символи 0-9" placement="right">
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </div>
                  <span>MRP:</span>
                  <div className={styles.tooltipWrapper}>
                    <InputField
                      name="mrp"
                      maxLength={20}
                      value={values.mrp}
                      disabled={getIsMRPBlocked(values.countryId) || !values.countryId}
                      extendedOnChange={(event) => {
                        const inputValue = event.target.value;

                        if (inputValue.startsWith(' ')) {
                          event.target.value = inputValue.trim();
                          return;
                        }

                        event.target.value = inputValue.replace(/\s{2,}/g, ' ');

                        setFieldValue('mrp', event.target.value);
                      }}
                    />
                    {getIsMRPBlocked(values.countryId) === false ? (
                      <Tooltip title="Максимальна роздрібна ціна." placement="right">
                        <HelpOutlineIcon className={styles.helper} />
                      </Tooltip>
                    ) : null}
                  </div>

                  <span>'Спожити до' на упаковці:</span>
                  <div className={styles.tooltipWrapper}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={expirationDate}
                        views={['year', 'month', 'day']}
                        onChange={handleChangeExpDate}
                        renderInput={(params) => (
                          <TextField onKeyDown={(event) => event.preventDefault()} sx={calendarStyle} {...params} />
                        )}
                        PaperProps={{
                          sx: {
                            '.MuiPickersCalendarHeader-label': {
                              fontSize: '13px',
                            },
                            '& .PrivatePickersYear-yearButton': {
                              fontSize: '13px',
                            },
                            '& .MuiTypography-root': {
                              fontSize: '13px',
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>

                    <Tooltip
                      title="Термін придатності, який буде виведено на маркуванні у вигляді тексту. Звіряйте з сертифікатом на серію."
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </div>

                  <span>'Спожити до' в Datamatrix (AI17):</span>
                  <div className={styles.tooltipWrapper}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={aI17}
                        views={['year', 'month', 'day']}
                        onChange={handleChangeAI17Date}
                        disabled={getIsAI17Blocked(values.countryId) || !values.countryId}
                        renderInput={(params) => (
                          <TextField onKeyDown={(event) => event.preventDefault()} sx={calendarStyle} {...params} />
                        )}
                        PaperProps={{
                          sx: {
                            '.MuiPickersCalendarHeader-label': {
                              fontSize: '13px',
                            },
                            '& .PrivatePickersYear-yearButton': {
                              fontSize: '13px',
                            },
                            '& .MuiTypography-root': {
                              fontSize: '13px',
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <Tooltip
                      title="Термін придатності, який буде закодовано в блоці AI17 коду Datamatrix. Встановлюйте відповдно до правил країни експорту: Азербайджан - останній день попереднього місяця відносно місяця в сертифікаті серії; Киргизія - перший день місяця, якщо конкретну дату не зазанчено в сертифікаті серії, або конкретну дату, яку зазначено. Для інших країн експорту керуйтесь відповідними настановами."
                      placement="right"
                    >
                      <ReportProblemOutlinedIcon className={styles.warnhelper} />
                    </Tooltip>
                  </div>

                  <span>Дата виробництва:</span>
                  <div className={styles.tooltipWrapper}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={mfDate}
                        views={['year', 'month', 'day']}
                        onChange={handleChangeMfDate}
                        renderInput={(params) => (
                          <TextField onKeyDown={(event) => event.preventDefault()} sx={calendarStyle} {...params} />
                        )}
                        PaperProps={{
                          sx: {
                            '.MuiPickersCalendarHeader-label': {
                              fontSize: '13px',
                            },
                            '& .PrivatePickersYear-yearButton': {
                              fontSize: '13px',
                            },
                            '& .MuiTypography-root': {
                              fontSize: '13px',
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    <Tooltip
                      title="Передається в системи Узбекистану і Казахстану. Виводиться на маркування, якщо присутнє в шаблоні."
                      placement="right"
                    >
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </div>

                  <span>Кількість одиниць:</span>
                  <div className={styles.tooltipWrapper}>
                    <InputField name="quantityToPrint" type="number" maxLength={6} value={values.quantityToPrint} />
                    <Tooltip title="Планова кількість одиниць по даному рецепту." placement="right">
                      <HelpOutlineIcon className={styles.helper} />
                    </Tooltip>
                  </div>
                </form>
                {hasError ? (
                  <span className={styles.error}>{createReceiptResponse?.UserText}</span>
                ) : (
                  <span className={styles.error} />
                )}

                <div className={styles.buttonWrapper}>
                  <Button
                    variant="contained"
                    className={styles.btn}
                    disabled={
                      createLoading ||
                      !values.series ||
                      !values.quantityToPrint ||
                      !values.productionLineId ||
                      !values.productId ||
                      !values.countryId ||
                      (!getIsMRPBlocked(values.countryId) && values.mrp === null) ||
                      (!getIsMRPBlocked(values.countryId) && values.mrp === '')
                    }
                    onClick={() => onSubmit(values)}
                  >
                    Активувати
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </Box>
    </Modal>
  );
};
