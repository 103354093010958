import React, { useEffect } from 'react';
import { Box, Button, Modal, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';

import styles from './PrintCodesInfoModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { postPrintJobDetailedRequest } from '../services/actions';
import { printCodesSelector } from '../services/selector';
import useModal from '../../../shared/hooks/useModal/useModal';
import { PrintCodeInnerModal } from './PrintCodeInnerModal';
import { Spinner } from '../../../common/Spinner/Spinner';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 800,
  bgcolor: '#ECEFF1 !important',
  boxShadow: 24,
  display: 'flex',
  p: 4,
  flexDirection: 'column',
};

interface Props {
  isShown: boolean;
  closeModal: () => void;
  params: any;
  filterPrintJobs: () => void;
}

export const PrintCodesInfoModal = ({ isShown, closeModal, params, filterPrintJobs }: Props) => {
  const dispatch = useDispatch();
  const { isShow: isShowInnerModal, openModal: openInnerModal, closeModal: closeInnerModal } = useModal();

  const { modalLoading, innerModalLoading, printCodesDetailed, postUnreserveMarkingCodes } =
    useSelector(printCodesSelector);

  useEffect(() => {
    if (isShown) {
      dispatch(postPrintJobDetailedRequest(params));
    }
  }, [dispatch, isShown, params]);

  return (
    <div>
      <Modal
        open={isShown}
        onClose={() => {
          closeModal();
          filterPrintJobs();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <span className={styles.responseText}>{postUnreserveMarkingCodes ?? ''}</span>
          <div className={styles.spinnerWrapper}>{modalLoading || innerModalLoading ? <Spinner /> : null}</div>
          <div className={styles.buttonsWrapper}>
            <RefreshIcon
              onClick={() => (isShowInnerModal ? () => undefined : dispatch(postPrintJobDetailedRequest(params)))}
            />
            <CloseIcon
              onClick={() => {
                closeModal();
                filterPrintJobs();
              }}
            />
          </div>
          <div className={styles.table}>
            <div className={styles.firstColumn}>
              <span>Номер завдання:</span>
              <span>Номер рецепту:</span>
              <span>Статус:</span>
              <span>Station ID:</span>
              <span>Назва шаблону:</span>
              <span>Створив:</span>
              <span>Дата створення:</span>
              <span>Дата завершення:</span>
              <span>Тривалість, хв:</span>
              <span>Швидкість кодів/хв:</span>
              <span></span>
              <span></span>
              <span></span>
              <span>GTIN:</span>
              <span>Внутрішня назва продукції:</span>
              <span>Експортна назва продукції:</span>
              <span>Країна:</span>
              <span>Серія:</span>
              <span>'Спожити до' на упаковці:</span>
              <span>'Спожити до' по сертифікату (AI17):</span>
              <span>Дата фактичного виробництва:</span>
              <span></span>
              <span></span>
              <span></span>
              <span>В завданні, шт:</span>
              <span>Зараз заброньовано, шт:</span>
              <span>Надруковано, шт:</span>
              <span>Агреговано, шт:</span>
              <span>Не агреговано, шт:</span>
              <span>Доступно для повторного друку:</span>
            </div>
            <div className={styles.column}>
              <span>{printCodesDetailed?.number ?? ' '}</span>
              <span>{printCodesDetailed?.receiptId ?? ' '}</span>
              <span>
                {printCodesDetailed?.jobStateDescription === null ? 'n/a' : printCodesDetailed?.jobStateDescription}
              </span>
              <span>{printCodesDetailed?.stationId ?? ' '}</span>
              <span>
                {printCodesDetailed?.labelTemplateName === null ? 'n/a' : printCodesDetailed?.labelTemplateName}
              </span>
              <span>{printCodesDetailed?.login === null ? 'n/a' : printCodesDetailed?.login}</span>
              <span>{printCodesDetailed?.startDate === null ? 'n/a' : printCodesDetailed?.startDate}</span>
              <span>
                {printCodesDetailed?.endDate === null ? 'завдання ще не завершено' : printCodesDetailed?.endDate}
              </span>
              <span>
                {printCodesDetailed?.jobDuration === null
                  ? 'завдання ще не завершено'
                  : printCodesDetailed?.jobDuration}
              </span>
              <span>{printCodesDetailed?.printSpeed === null ? 'n/a' : printCodesDetailed?.printSpeed}</span>
              <span></span>
              <span></span>
              <span></span>
              <span>{printCodesDetailed?.gtin === '' ? 'n/a' : printCodesDetailed?.gtin}</span>
              {printCodesDetailed?.internalName?.length > 70 ? (
                <span>
                  {printCodesDetailed?.internalName?.substring(0, 70)}
                  <Tooltip title={printCodesDetailed.internalName} placement="right">
                    <span> ...</span>
                  </Tooltip>
                </span>
              ) : (
                <span>{printCodesDetailed.internalName}</span>
              )}
              {printCodesDetailed?.marketName?.length > 70 ? (
                <span>
                  {printCodesDetailed?.marketName?.substring(0, 70)}
                  <Tooltip title={printCodesDetailed?.marketName} placement="right">
                    <span> ...</span>
                  </Tooltip>
                </span>
              ) : (
                <span>{printCodesDetailed?.marketName}</span>
              )}
              <span>{printCodesDetailed?.countryName === '' ? 'n/a' : printCodesDetailed?.countryName}</span>
              <span>{printCodesDetailed?.series === '' ? 'n/a' : printCodesDetailed?.series}</span>
              <span>{printCodesDetailed?.expirationDate === '' ? 'n/a' : printCodesDetailed?.expirationDate}</span>
              <span>{printCodesDetailed?.AI17 === null ? 'n/a' : printCodesDetailed?.AI17}</span>
              <span>{printCodesDetailed?.mfDate === '' ? 'n/a' : printCodesDetailed?.mfDate}</span>
              <span></span>
              <span></span>
              <span></span>
              <span>{printCodesDetailed?.toPrintCount ?? 'n/a'}</span>
              <span>{printCodesDetailed?.booked ?? 'n/a'}</span>
              <span>{printCodesDetailed?.printedCount ?? 'n/a'}</span>
              <span>{printCodesDetailed?.aggregated ?? 'n/a'}</span>
              <span>{printCodesDetailed?.notAggregated ?? 'n/a'}</span>
              <span>{printCodesDetailed?.endDate === null ? 'n/a' : printCodesDetailed?.notAggregated}</span>
            </div>
          </div>
          <div className={styles.mainButtonWrapper}>
            <Button
              variant="contained"
              className={styles.btn}
              disabled={
                printCodesDetailed?.notAggregated === 0 ||
                printCodesDetailed?.endDate === null ||
                modalLoading ||
                innerModalLoading
              }
              onClick={openInnerModal}
            >
              <span className={styles.btnText}>Передрукувати неагреговані коди</span>
            </Button>
          </div>
        </Box>
      </Modal>
      {isShown && <PrintCodeInnerModal isShown={isShowInnerModal} closeModal={closeInnerModal} params={params} />}
    </div>
  );
};
