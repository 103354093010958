import DownloadIcon from '@mui/icons-material/Download';
import { ShippmentReportTable } from '../../common/ShippmentReportTable/ShippmentReportTable';
import { disabledInputStyle, inputStyle } from '../CodesReport/services/style';
import { getGlobalShipReportRequest, getShipmentReportRequest, getShipmentReportSuccess } from './services/actions';
import styles from './Shipment.module.scss';
import { Spinner } from '../../common/Spinner/Spinner';
import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import moment from 'moment';
import InputSearch from '../../common/InputSearch';
import { calendarStyleEnabled, calendarStyleDisabled, selectStyle } from './muiStyles/muiStyles';
import { useDispatch, useSelector } from 'react-redux';
import { shipmentReportSelector } from './services/selector';
import ErrorResponse from '../../shared/ErrorResponse';
import ArrowBackIcon from '../../../icons/arrow_back.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';
import 'moment/locale/uk';

export const Shipment = () => {
  const dispatch = useDispatch();

  const [viewType, setViewType] = useState<number>(1);
  const [selectValue, setSelectValue] = useState<any>(undefined);
  const [selectReportTypeValue, setReportTypeValue] = useState<any>(1);
  const [seriesValue, setSeriesValue] = useState<any>('');
  const [numValue, setNumValue] = useState<any>('');

  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const changeDateCalendar = (calendarValue: any) => {
    const newDate = new Date(calendarValue);
    newDate.setMonth(newDate.getMonth());
    setCalendarDate(newDate);
  };

  const { loading, shipmentReportResponse, shipmentReportErrorResponses } = useSelector(shipmentReportSelector);

  const [tableData, setTableData] = useState<any[]>(shipmentReportResponse);

  const showHandler = () => {
    if (viewType === 1) {
      dispatch(
        getShipmentReportRequest({
          fromDate: moment(calendarDate).format('YYYY-MM-01'),
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
          level: selectValue,
        }),
      );
    }
    if (viewType === 2) {
      dispatch(getShipmentReportRequest({ Series: seriesValue, level: selectValue }));
    }
    if (viewType === 3) {
      dispatch(getShipmentReportRequest({ externalShippingDocumentNumber: numValue, level: selectValue }));
    }
  };

  const onExcelGlobalDownload = () => {
    const ssccs: any = [];
    if (selectValue === 2) {
      tableData.map((item: any) => ssccs.push(item.sscc));
    } else {
      tableData.map((item: any) => ssccs.push(item.palletSscc));
    }
    dispatch(getGlobalShipReportRequest({ SSCCs: ssccs, level: selectValue, reportType: selectReportTypeValue }));
  };

  return (
    <div className={styles.wrapper}>
      {Object.keys(shipmentReportErrorResponses).length !== 0 ? (
        <ErrorResponse error={shipmentReportErrorResponses} />
      ) : null}
      <div className={styles.loadingGroup}>
        <span className={styles.title}>Звіт з відвантаження</span>
        {loading ? <Spinner /> : null}
      </div>
      <span>Перегляд продукції, що відвантажено з заводу</span>
      <div className={styles.filterBlock}>
        <div className={styles.firstRow}>
          <div className={styles.calendarItem}>
            <span>Пошук по:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setViewType(e.target.value);
                setSeriesValue('');
                setNumValue('');
                setTableData([]);
              }}
              value={viewType}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={1}>
                Дата пакування
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={2}>
                Серія
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={3}>
                Номер накладної
              </MenuItem>
            </Select>
          </div>
          <div className={styles.calendarItem}>
            <span>Дані за пeріод:</span>
            <div className={styles.calendarRow}>
              <LocalizationProvider dateAdapter={AdapterMoment} locale={'uk'}>
                <DatePicker
                  inputFormat="MM/YYYY"
                  value={calendarDate}
                  views={['year', 'month']}
                  onChange={changeDateCalendar}
                  disabled={viewType !== 1}
                  renderInput={(params) => (
                    <TextField sx={viewType !== 1 ? calendarStyleDisabled : calendarStyleEnabled} {...params} />
                  )}
                  PaperProps={{
                    sx: {
                      '.MuiPickersCalendarHeader-label': {
                        fontSize: '13px',
                      },
                      '& .PrivatePickersYear-yearButton': {
                        fontSize: '13px',
                      },
                      '& .MuiTypography-root': {
                        fontSize: '13px',
                      },
                    },
                  }}
                />
                <Tooltip title="Місяць назад" placement="top">
                  <img
                    src={ArrowBackIcon}
                    onClick={() => (viewType !== 1 ? () => undefined : handleMonthChange(-1))}
                    alt=""
                  />
                </Tooltip>
                <Tooltip title="Місяць вперед" placement="top">
                  <img
                    src={ArrowForwardIcon}
                    onClick={() => (viewType !== 1 ? () => undefined : handleMonthChange(1))}
                    alt=""
                  />
                </Tooltip>
              </LocalizationProvider>
            </div>
          </div>
          <div className={styles.calendarItem}>
            <span>Серія:</span>
            <OutlinedInput
              name="Series"
              value={seriesValue}
              onChange={(event) => {
                setSeriesValue(event.target.value.trim());
                setTableData([]);
              }}
              sx={viewType === 2 ? inputStyle : disabledInputStyle}
              disabled={viewType === 1 || viewType === 3}
              inputProps={{ maxLength: 20 }}
            />
          </div>
          <div className={styles.calendarItem}>
            <span>Номер накладної:</span>
            <OutlinedInput
              name="num"
              value={numValue}
              onChange={(event) => {
                setNumValue(event.target.value.trim());
                setTableData([]);
              }}
              sx={viewType === 3 ? inputStyle : disabledInputStyle}
              disabled={viewType === 1 || viewType === 2}
              inputProps={{ maxLength: 20 }}
            />
          </div>
          <div className={styles.calendarItem}>
            <span>Відобразити список як:</span>
            <Select
              sx={selectStyle}
              onChange={(e: SelectChangeEvent<any>) => {
                setSelectValue(e.target.value);
                dispatch(getShipmentReportSuccess([]));
                setTableData([]);
              }}
              value={selectValue}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={2}>
                Ящики (Level 2)
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={3}>
                Палети (Level 3)
              </MenuItem>
            </Select>
          </div>
          <Button
            variant="contained"
            className={styles.btn}
            disabled={
              selectValue === undefined ||
              (viewType === 2 && seriesValue.trim().length === 0) ||
              (viewType === 3 && numValue.trim().length === 0)
            }
            onClick={showHandler}
          >
            <span className={styles.btnText}>Пошук</span>
          </Button>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.searchGroup}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={shipmentReportResponse} clearOnPropsChange={false} />
          </div>
          <div className={styles.searchGroup}>
            <span>Скачати як zip-звіт в форматі:</span>
            <Select
              sx={selectStyle}
              disabled={tableData.length === 0}
              onChange={(e: SelectChangeEvent<any>) => {
                setReportTypeValue(e.target.value);
              }}
              value={selectReportTypeValue}
            >
              <MenuItem sx={{ fontSize: '13px' }} value={1}>
                Таблиця Excel
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={3}>
                Документ 'АИК' формату Turon, Узбекистан
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={4}>
                Документ формату ndb.med.kg, Киргизія
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={5}>
                Документ формату 'ЦЭДМ', Казахстан
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={6}>
                Документ формату DRAoP, Пакистан
              </MenuItem>
              <MenuItem sx={{ fontSize: '13px' }} value={7}>
                Документ RASD формату SFDA, Саудівська Аравія
              </MenuItem>
            </Select>
          </div>
          {tableData.length > 0 && selectValue !== undefined ? (
            <div className={styles.downloadWrapper}>
              {!loading ? (
                <a onClick={onExcelGlobalDownload}>
                  <DownloadIcon className={styles.downloadIcon} />
                </a>
              ) : (
                <DownloadIcon className={styles.downloadIcon} />
              )}
            </div>
          ) : null}
        </div>
      </div>
      {selectValue !== undefined ? <ShippmentReportTable select={selectValue} data={tableData} /> : null}
    </div>
  );
};
