import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import styles from './BackFlowProcesses.module.scss';
import { getBackgroundProcessesRequest } from './services/actions';
import { backFlowProcessesSelector } from './services/selector';
import { Spinner } from '../../common/Spinner/Spinner';
import ErrorResponse from '../../shared/ErrorResponse';
import { BasicBars } from '../../common/BarChart';

export const BackFlowProcesses = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { loading, backgroundProcessesLoading, backgroundProcesses, backgroundProcessesErrorResponses } =
    useSelector(backFlowProcessesSelector);

  const today = new Date();
  const tenDaysAgo = moment(today).subtract(10, 'days').format('YYYY-MM-DD');

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [refreshOnClick, setRefreshOnClick] = useState(false);

  useEffect(() => {
    dispatch(getBackgroundProcessesRequest(tenDaysAgo));
  }, [dispatch, tenDaysAgo]);

  useEffect(() => {
    if (backgroundProcessesErrorResponses?.status === 403) {
      navigate('/error');
    }
  }, [backgroundProcessesErrorResponses, navigate]);

  useEffect(() => {
    if (refreshOnClick && Object.keys(backgroundProcesses).length !== 0) {
      setIsButtonDisabled(true);
      const timer = setTimeout(() => {
        setIsButtonDisabled(false);
        setRefreshOnClick(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [backgroundProcesses]);

  const handleRefresh = () => {
    setRefreshOnClick(true);
    dispatch(getBackgroundProcessesRequest(tenDaysAgo));
  };

  return (
    <div className={styles.wrapper}>
      {Object.keys(backgroundProcessesErrorResponses).length !== 0 ? (
        <ErrorResponse error={backgroundProcessesErrorResponses} />
      ) : null}

      <div className={styles.loadingGroup}>
        <div>
          <span className={styles.title}>Моніторинг фонових процесів</span>
          {(loading || backgroundProcessesLoading) && <Spinner />}
        </div>

        <IconButton disabled={isButtonDisabled || backgroundProcessesLoading} onClick={handleRefresh}>
          <RefreshIcon className={styles.refreshIcon} />
        </IconButton>
      </div>

      <span className={styles.subtitle}>Стан виконання автономних задач обміну даними з системами реєстраторів</span>

      {Object.keys(backgroundProcesses).length !== 0 && (
        <>
          <section className={styles.main}>
            <span className={styles.queueTitle}>Черги поточних процесів:</span>
            <div className={styles.dataTable}>
              <div className={styles.column}>
                <span>Кількість повідомлень в черзі LegacyAPI-Utilization (реєстрація, Узбекистан):</span>
                <span>Кількість повідомлень в черзі LegacyAPI-Aggrigation (агрегація, Узбекистан):</span>
                <span>Кількість повідомлень в черзі TrueApi-Validation (контроль, Узбекистан):</span>
                <span>Кількість повідомлень в черзі UFE-Integration (передача дистрибутору):</span>
              </div>
              <div className={styles.column}>
                <span>{backgroundProcesses?.uzUtilizationMessagesCount}</span>
                <span>{backgroundProcesses?.uzAggregationMessagesCount}</span>
                <span>{backgroundProcesses?.uzTrueApiCheckMessagesCount}</span>
                <span>{backgroundProcesses?.ufeIntegrationMessagesCount}</span>
              </div>
            </div>
          </section>

          {backgroundProcesses?.conveyors?.map((conveyor: any) => (
            <section key={conveyor.conveyorName} className={styles.conveyors}>
              <span className={styles.conveyorsTitle}>
                {t('backgroundProcessesChartName_1_2')} {conveyor?.conveyorName} {t('backgroundProcessesChartName_2_2')}
              </span>

              <div className={styles.conveyorsGrid}>
                <div className={styles.conveyorsColumn}>
                  <BasicBars days={conveyor?.days} success={conveyor?.success} errors={conveyor?.fail} />
                </div>

                <div className={styles.conveyorsData}>
                  <div className={styles.conveyorsDataColumn}>
                    <span>Поточний стан:</span>
                    <span>Дата останнього виклику:</span>
                    <span>Статус останнього виклику:</span>
                    <span>Останній об’єкт:</span>
                  </div>
                  <div className={styles.conveyorsDataColumn}>
                    <span>{conveyor?.currentState}</span>
                    <span>{conveyor?.lastRequestDate}</span>
                    <span>{conveyor?.lastRequestStatusCode}</span>
                    <span>{conveyor?.lastRequestSscc}</span>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </>
      )}
    </div>
  );
};
