import MenuIcon from '@mui/icons-material/Menu';
import { DataGrid, GridRenderCellParams, ukUA } from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment/moment';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import ReportModal from '../../EventsLog/CodesReport/ReportModal';
import {
  getExcelFileRequest,
  getJSONFileRequest,
  putUpdateStatusSuccess,
} from '../../EventsLog/CodesReport/services/actions';
import useModal from '../../shared/hooks/useModal/useModal';
import AlertIcon from '../../../icons/alert.svg';
import Running from '../../../icons/directions_run.svg';
import CarIcon from '../../../icons/car.svg';
import RedCarIcon from '../../../icons/redcar.svg';
import StopIcon from '../../../icons/stop.svg';
import Done_all from '../../../icons/done_all.svg';
import GrayPallet from '../../../icons/graypallet.svg';
import Wait from '../../../icons/sandwatch.svg';
import Green from '../../../icons/colorboxes/greenbox.svg';
import Yellow from '../../../icons/colorboxes/yellowbox.svg';
import Red from '../../../icons/colorboxes/redbox.svg';
import styles from '../DataTable/DataTable.module.scss';
import { style } from '../DataTable/style';

interface Props {
  data: any;
  select?: number;
  updateTable: () => void;
}

export const AgregationReportTable: React.FC<Props> = ({ data, select, updateTable }) => {
  const [pageSize, setPageSize] = React.useState(15);
  const [selectedRow, setSelectedRow] = useState(null);
  const { isShow, openModal, closeModal } = useModal();
  const dispatch = useDispatch();

  const renderInternalAggregationStatus = (element: any) => {
    switch (element.aggregationStatus) {
      case null:
        return <div>{''}</div>;
      case 1:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Ящик агреговано, але код підтвердження не відскановано оператором сканера" placement="top">
              <img src={Yellow} alt="" />
            </Tooltip>
            <span>{element.aggregationStatusDescription}</span>
          </div>
        );
      case 2:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Ящик агреговано і підтвердженно" placement="top">
              <img src={Green} alt="" />
            </Tooltip>
            <span>{element.aggregationStatusDescription}</span>
          </div>
        );
      case 4:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Ящик деагреговано оператором сканера чи порталу" placement="top">
              <img src={Red} alt="" />
            </Tooltip>
            <span>{element.aggregationStatusDescription}</span>
          </div>
        );
      case 6:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Під час агрегації іншого ящика було відскановано продукцію з даного ящика." placement="top">
              <img src={Red} alt="" />
            </Tooltip>
            <span>{element.aggregationStatusDescription}</span>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  const renderExternalAggregationStatus = (element: any) => {
    switch (element.severityLevel) {
      case null:
        return (
          <div className={styles.customCell}>
            <span>{element.externalAggregationStatusDescription}</span>
          </div>
        );
      case 1:
        return (
          <div className={styles.customCell}>
            <img src={Running} alt="" />
            <Tooltip title="Виконайте агрегацію в палету" placement="top">
              <img src={GrayPallet} alt="" />
            </Tooltip>
            <span>{element.externalAggregationStatusDescription}</span>
          </div>
        );
      case 2:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Очікуйте виконання фонових процесів" placement="top">
              <img src={Wait} alt="" />
            </Tooltip>
            <span>{element.externalAggregationStatusDescription}</span>
          </div>
        );
      case 3:
        return (
          <div className={styles.customCell}>
            <Tooltip
              title="Помилка під час виконання фонових процесів. Реагрегуйте ящик і при повторенні зверніться в підтримку"
              placement="top"
            >
              <img src={AlertIcon} alt="" />
            </Tooltip>
            <span>{element.externalAggregationStatusDescription}</span>
          </div>
        );
      case 4:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Фонові процеси успішно виконано" placement="top">
              <img src={Done_all} alt="" />
            </Tooltip>
            <span>{element.externalAggregationStatusDescription}</span>
          </div>
        );
      case 5:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Контрольний звіт не пройдено, зверніться в підтримку" placement="top">
              <img src={StopIcon} alt="" />
            </Tooltip>
            <span>{element.externalAggregationStatusDescription}</span>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  const renderBoxShippingIsAllowed = (element: any) => {
    switch (element.shippingIsAllowed) {
      case null:
        return <div>{''}</div>;
      case false:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Відвантаження заборонено" placement="top">
              <img src={RedCarIcon} alt="" />
            </Tooltip>
          </div>
        );
      case true:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Відвантаження дозволено" placement="top">
              <img src={CarIcon} alt="" />
            </Tooltip>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  const renderPalletShippingIsAllowed = (element: any) => {
    switch (element.shippingIsAllowed) {
      case null:
        return <div>{''}</div>;
      case false:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Відвантаження заборонено" placement="top">
              <img src={RedCarIcon} alt="" />
            </Tooltip>
          </div>
        );
      case true:
        return (
          <div className={styles.customCell}>
            <Tooltip title="Відвантаження дозволено" placement="top">
              <img src={CarIcon} alt="" />
            </Tooltip>
          </div>
        );
      default:
        return <div>{''}</div>;
    }
  };

  const boxGrid = [
    { field: 'sscc', headerName: 'SSCC код ящика', width: 160 },
    {
      field: 'packingDate',
      headerName: 'Дата пакування',
      width: 140,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span>{moment(params.row.packingDate).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
    { field: 'packingId', headerName: 'ID пакування', width: 55 },
    { field: 'ean13', headerName: 'Код GTIN', width: 130 },
    { field: 'internalProductName', headerName: 'Внутрішня назва продукції', minWidth: 310, flex: 0.9 },
    { field: 'series', headerName: 'Серія', width: 120 },
    { field: 'countryCode', headerName: 'Країна', width: 80 },
    { field: 'unitsCount', headerName: 'Одиниць продукції', width: 100 },
    { field: 'palletSscc', headerName: 'Агреговано в палету', width: 160 },
    {
      field: 'aggregationStatusDescription',
      headerName: 'Статус ящика',
      width: 180,
      renderCell: (params: GridRenderCellParams<Date>) => renderInternalAggregationStatus(params.row),
    },
    {
      field: 'externalAggregationStatus',
      headerName: 'Фонові процеси',
      width: 200,
      renderCell: (params: GridRenderCellParams<Date>) => renderExternalAggregationStatus(params.row),
    },
    {
      field: 'shippingIsAllowed',
      headerName: 'Статус відвантаження',
      width: 120,
      renderCell: (params: GridRenderCellParams<Date>) => renderBoxShippingIsAllowed(params.row),
    },
    {
      field: 'button',
      headerName: 'Меню',
      maxWidth: 100,
      sortable: false,
      filterable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Date>) => (
        <>
          {params?.row?.allowDownload ? (
            <>
              <Tooltip title="Скачати як звіт в Excel" placement="top">
                <a
                  onClick={() =>
                    dispatch(
                      getExcelFileRequest({
                        SSCC: select === 2 ? params.row.sscc : params.row.palletSscc,
                        level: select,
                      }),
                    )
                  }
                >
                  <DownloadIcon className={styles.icon} />
                </a>
              </Tooltip>
              <Tooltip title="Скачати останнє повідомлення конвеєра Legacy\True API (UZ)" placement="top">
                <a
                  onClick={() =>
                    dispatch(
                      getJSONFileRequest({
                        SSCC: select === 2 ? params.row.sscc : params.row.palletSscc,
                        level: select,
                      }),
                    )
                  }
                >
                  <CloudUploadOutlinedIcon className={styles.icon} />
                </a>
              </Tooltip>
              <Tooltip title="Зміна статусу SSCC" placement="top">
                <MenuIcon
                  className={styles.icon}
                  onClick={() => {
                    dispatch(putUpdateStatusSuccess({}));
                    setSelectedRow(params.row);
                    openModal();
                  }}
                />
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const palletGrid = [
    { field: 'palletSscc', headerName: 'SSCC код палети', width: 160 },
    {
      field: 'packingDate',
      headerName: 'Дата пакування',
      width: 140,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span>{moment(params.row.packingDate).format('DD-MM-YYYY HH:mm:ss')}</span>
      ),
    },
    {
      field: 'ean13',
      headerName: 'Код GTIN',
      width: 130,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span>{params.row.ean13 === 0 ? ' ' : params.row.ean13}</span>
      ),
    },
    { field: 'internalProductName', headerName: 'Внутрішня назва продукції', minWidth: 360, flex: 1 },
    { field: 'series', headerName: 'Серія', width: 120 },
    { field: 'countryCode', headerName: 'Країна', width: 80 },
    {
      field: 'unitsCount',
      headerName: 'Ящиків',
      width: 110,
      renderCell: (params: GridRenderCellParams<Date>) => (
        <span>{params.row.unitsCount === 0 ? ' ' : params.row.unitsCount}</span>
      ),
    },
    {
      field: 'aggregationStatusDescription',
      headerName: 'Статус палети',
      width: 180,
      renderCell: (params: GridRenderCellParams<Date>) => renderInternalAggregationStatus(params.row),
    },
    {
      field: 'shippingIsAllowed',
      headerName: 'Статус відвантаження',
      width: 120,
      renderCell: (params: GridRenderCellParams<Date>) => renderPalletShippingIsAllowed(params.row),
    },
    {
      field: 'button',
      headerName: 'Меню',
      maxWidth: 110,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Date>) => (
        <>
          {params?.row?.allowDownload ? (
            <>
              <Tooltip title="Скачати як звіт в Excel" placement="top">
                <a
                  onClick={() =>
                    dispatch(
                      getExcelFileRequest({
                        SSCC: select === 2 ? params.row.sscc : params.row.palletSscc,
                        level: select,
                      }),
                    )
                  }
                >
                  <DownloadIcon className={styles.icon} />
                </a>
              </Tooltip>
              <MenuIcon
                className={styles.icon}
                onClick={() => {
                  setSelectedRow(params.row);
                  openModal();
                }}
              />
            </>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 599, width: '100%', marginTop: '20px' }}>
      <DataGrid
        localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        headerHeight={34}
        rowHeight={34}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        // @ts-expect-error
        columns={select === 2 ? boxGrid : palletGrid}
        getRowId={() => Math.random()}
        disableSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        sx={style}
        componentsProps={{
          pagination: {
            SelectProps: {
              MenuProps: {
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: 14,
                    bgcolor: '#ffffff',
                  },
                },
                PaperProps: {
                  sx: {
                    bgcolor: '#ffffff',
                  },
                },
              },
            },
          },
        }}
      />
      {isShow && (
        <ReportModal
          header={`Зміна статусу SSCC ${
            // @ts-expect-error
            select === 2 && selectedRow ? selectedRow?.sscc : selectedRow?.palletSscc
          }`}
          isShow={isShow}
          select={select}
          data={selectedRow}
          closeModal={() => {
            closeModal();
            setSelectedRow(null);
          }}
          applyHandler={closeModal}
          updateTable={updateTable}
        />
      )}
    </div>
  );
};
