import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputSearch from '../../common/InputSearch';
import styles from './InvoiceReport.module.scss';
import { getInvoicesRequest } from './services/actions';
import { invoiceReportSelector } from './services/selector';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, TextField } from '@mui/material';
import moment from 'moment';
import { Spinner } from '../../common/Spinner/Spinner';
import ErrorResponse from '../../shared/ErrorResponse';
import { InvoicesReportTable } from './components/InvoicesReportTable';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '../../../icons/arrow_back.svg';
import ArrowForwardIcon from '../../../icons/arrow_forward.svg';

const calendarStyle = {
  '.MuiInputBase-formControl': {
    backgroundColor: '#ffffff !important',
    height: 30,
    fontSize: '13px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#B0BEC5 !important',
    fontSize: '13px !important',
  },
  '.MuiSvgIcon-root': {
    fontSize: '18px !important',
  },
};

export const InvoiceReport: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let timeoutId: NodeJS.Timeout | null = null;

  const [calendarDate, setCalendarDate] = useState<any>(new Date());

  const handleMonthChange = (increment: number) => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setCalendarDate(newDate);
  };

  const filterAvailableCodes = () => {
    dispatch(
      getInvoicesRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  };

  const changeDateCalendar = (calendarValue: any) => {
    const newDate = new Date(calendarValue);
    newDate.setMonth(newDate.getMonth());
    setCalendarDate(newDate);
  };

  useEffect(() => {
    timeoutId = setTimeout(() => {
      filterAvailableCodes();
      timeoutId = null;
    }, 1000);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [calendarDate]);

  const { invoicesReportResponse, loading, invoicesErrorResponses, detailedInvoiceReportErrorResponses } =
    useSelector(invoiceReportSelector);

  const [tableData, setTableData] = useState<any[]>(invoicesReportResponse);

  useEffect(() => {
    dispatch(
      getInvoicesRequest({
        fromDate: moment(calendarDate).format('YYYY-MM-01'),
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        toDate: moment(calendarDate).format('YYYY-MM-') + moment(calendarDate).daysInMonth(),
      }),
    );
  }, []);

  useEffect(() => {
    if (Object.keys(invoicesReportResponse).length !== 0 && invoicesReportResponse?.status === 403) {
      navigate('/error');
    }
  }, [invoicesReportResponse]);

  return (
    <div className={styles.wrapper}>
      {Object.keys(invoicesErrorResponses).length !== 0 ? <ErrorResponse error={invoicesErrorResponses} /> : null}
      {Object.keys(detailedInvoiceReportErrorResponses).length !== 0 ? (
        <ErrorResponse error={detailedInvoiceReportErrorResponses} />
      ) : null}
      <section className={styles.codesHeader}>
        <div className={styles.loadingGroup}>
          <span className={styles.bankCodesSubTitle}>Звіт по накладним</span>
          {loading ? <Spinner /> : null}
        </div>
        <span className={styles.bankCodesAdditionalText}>
          Список документів, по яким здійснювалось відвантаження чи переміщення з виробництва
        </span>
      </section>
      <section className={styles.block}>
        <div className={styles.tools}>
          <div className={styles.calendarBlock}>
            <div className={styles.calendarItem}>
              <span>Дані за пeріод:</span>
              <div className={styles.calendarRow}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    inputFormat="MM/YYYY"
                    value={calendarDate}
                    views={['year', 'month']}
                    onChange={changeDateCalendar}
                    renderInput={(params) => <TextField sx={calendarStyle} {...params} />}
                    PaperProps={{
                      sx: {
                        '.MuiPickersCalendarHeader-label': {
                          fontSize: '13px',
                        },
                        '& .PrivatePickersYear-yearButton': {
                          fontSize: '13px',
                        },
                        '& .MuiTypography-root': {
                          fontSize: '13px',
                        },
                      },
                    }}
                  />
                  <img src={ArrowBackIcon} onClick={() => handleMonthChange(-1)} alt="" />
                  <img src={ArrowForwardIcon} onClick={() => handleMonthChange(1)} alt="" />
                </LocalizationProvider>
              </div>
            </div>

            <Button variant="contained" className={styles.btn} onClick={filterAvailableCodes}>
              <span className={styles.btnText}>Пошук</span>
            </Button>
          </div>
        </div>
        <div className={styles.secondRow}>
          <div className={styles.searchBlock}>
            <span>Фільтр по всім полям:</span>
            <InputSearch setTableData={setTableData} data={invoicesReportResponse} clearOnPropsChange={false} />
          </div>
        </div>
      </section>
      <InvoicesReportTable data={tableData} />
    </div>
  );
};
